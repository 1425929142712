import React from 'react';
import styled from 'styled-components';
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";

import { breakpoints } from "../styles/breakpoints";
import Layout from "../components/Layout";

const PageHeader = styled.div`
    margin: 100px 0 40px;
    @media (min-width: ${breakpoints.small}) {
        margin: 140px 0 68px;
    }
    h1 {
        font-size: 2rem;
        color: var(--green);
        text-align: center;
        padding: 0 20px;
        line-height: 1.3;
    }
`

const PriceBar = styled.div`
    width: 100%;
    background-color: var(--green-light);
    display: flex;
    align-items: center;
    font-family: var(--bodoni);
    color: var(--green);
    margin-bottom: 26px;
    @media (min-width: ${breakpoints.small}) {
        max-width: 780px;
        margin: 0 auto 40px;
        border-radius: 10px;
    }
    h2 {
        color: var(--green);
        font-size: 1.25rem;
        text-transform: uppercase;
        margin: 0 0 0 20px;
        @media (min-width: ${breakpoints.small}) {
            margin-left: 42px;
            font-size: 1.45rem;
        }
    }
    span {
        font-size: 1.875rem;
        padding: 14px;
        border-left: #fff 1px solid;
        margin-left: auto;
        padding: 14px 20px;
        @media (min-width: ${breakpoints.xSmall}) {
            padding: 14px 30px;
        }
        @media (min-width: ${breakpoints.small}) {
            padding: 14px 50px;
        }
    }
`

const Contain = styled.div`
    max-width: 700px;
    padding: 0 40px;
    margin: 0 auto 90px;
    p strong {
        font-size: 1.125rem;
    }
    ul li {
        color: var(--black);
        line-height: 1.4;
    }
`

const LongerSession = styled.div`
    width: calc(100% - 60px);
    background-color: var(--green-light);
    padding: 30px;
    text-align: center;
    color: var(--green);
    margin-bottom: 60px;
    text-transform: uppercase;
    letter-spacing: auto;
    @media (min-width: ${breakpoints.small}) {
        letter-spacing: 2px;
    }
`

const VacationNotice = styled.div`
    padding: 24px;
    background-color: var(--green-light);
    border-radius: 20px;
    margin-top: 60px;
    @media (min-width: ${breakpoints.medium}) {
        padding: 30px 60px;
    }
    h3 {
        font-size: 1.5rem;
        color: var(--green);
        text-transform: uppercase;
        font-family: var(--bodoni);
        font-weight: normal;
        margin-top: 0;
    }
    p {
        margin-bottom: 0;
    }
`

const Pricing = () => {
    return(
        <Layout alwaysSticky={true} currentPage={"pricing"}>
            <Helmet>
                <title>Pricing :: Tide &amp; Tone Photography</title>
            </Helmet>
            <PageHeader>
                <h1>Pricing &amp;<br className="mobile-show" /> What to Expect</h1>
                <p style={{textAlign: 'center'}}>Booking outside of Wilmington or its local beaches?<br />
                    <span 
                    onClick={() => {
                        scrollTo("#vacation-notice");
                    }}
                    style={{textDecoration: 'underline', display: 'block', marginBottom: '60px', cursor: 'pointer'}}>
                        See travel fees.
                    </span>
                </p>
            </PageHeader>
            <PriceBar>
                <h2>30 Minute Session</h2>
                <span>$250</span>
            </PriceBar>
            <Contain>
                <p><strong>Recommended for smaller families, .</strong></p>
                <p>20-30 high resolution, hand-edited images delivered.</p>
                <p>Link to download all edited photos at full resolution with option to purchase prints.</p>
                <p>Beautiful online gallery of your photographs.</p>
                <button
                    className="button primary center"
                    style={{margin: '40px auto 70px'}}
                    onClick={() => {
                        scrollTo("#contact-section");
                    }}
                >
                    Book Your Session!
                </button>
            </Contain>
            <PriceBar>
                <h2>60 Minute Session</h2>
                <span>$400</span>
            </PriceBar>
            <Contain>
                <p><strong>Recommended for larger families or if outfit changes are needed.</strong></p>
                <p>40-60+ high resolution, hand-edited images delivered.</p>
                <p>Link to download all edited photos at full resolution with option to purchase prints.</p>
                <p>Beautiful online gallery of your photographs.</p>
                <button
                    className="button primary center"
                    style={{margin: '40px auto 70px'}}
                    onClick={() => {
                        scrollTo("#contact-section");
                    }}
                >
                        Book Your Session!
                </button>
            </Contain>
            <LongerSession>
                <strong>Think you'll need a longer session?</strong>
                <button 
                    className="button primary green center"
                    style={{margin: '20px auto'}}
                    onClick={() => {
                        scrollTo("#contact-section");
                    }}
                >
                    Ask me about custom pricing!
                </button>
            </LongerSession>
            <Contain>
                <h2>What to Expect</h2>
                <p>Casual, playful, fun!</p>
                <p>My goal is to capture the more candid moments you share with your loved ones.</p>
                <p>I want you to remember the moments, not how you were posed.</p>
                <h2 style={{marginTop: '60px'}}>What to Wear</h2>
                <p>I usually leave this to my customers. Your comfort and enjoyment matter most.</p>
                <p>For footwear I recommend something to move around in. We usually go off the beaten path to find unique and picturesque locations to make your experience memorable and something you’ll be proud to share.</p>
                <VacationNotice id="vacation-notice">
                    <h3>Booking while on vacation?</h3>
                    <p>If you’re booking your photography session while on vacation, it’s recommended to book earlier in your stay just in case we need to reschedule due to bad weather.</p>
                </VacationNotice>
                <h2 style={{marginTop: '60px'}}>Travel Fees</h2>
                <p>A travel fee will be applied if you're booking more than 30 miles outside of Wilmington. See below for examples.</p>
                <ul>
                    <li>Oak Island, Surf City (~35 Miles): add $75</li>
                    <li>Ocean Isle, Topsail Beach, North Topsail Beach (~50 Miles): add $100</li>
                </ul>
            </Contain>
        </Layout>
    )
}
export default Pricing;